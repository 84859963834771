<template>
  <div class="appointment-view">
    <app-header icon="calendar-alt" title="Consulta" goes-back></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <div class="schedule">
            <form @submit.prevent>
              <div class="row">
                <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit-first">
                  <h2 class="subtitle">Contratantes</h2>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-6">

                  <b-field label="Médico">
                    <b-button
                        size="is-small"
                        type="is-info"
                        icon-left="external-link-square-alt"
                        @click="openUrl('/doctors/'+schedule.doctor.id)"
                    >
                      ver médico
                    </b-button>
                  </b-field>
                  <b-input
                      v-if="schedule.doctor.user"
                      name="name"
                      required
                      disabled
                      label="Médico"
                      v-model="schedule.doctor.name"
                      :icon-right-clickable="true"
                      icon-right="external-link-square-alt"
                      @icon-right-click="openUrl('/doctors/'+schedule.doctor.id)"
                  />

                  <b-field v-if="schedule.doctor.specialties && schedule.doctor.specialties.length > 0" label=" " class="field-specialty">
                    <b-tag
                       v-for="specialty in schedule.doctor.specialties" v-bind:key="specialty.id"
                       type='is-danger'
                       >
                      {{specialty.name}}
                    </b-tag>
                  </b-field>
                  <b-field v-if="schedule.doctor.subspecialties && schedule.doctor.subspecialties.length > 0" label="" >
                    <b-tag
                        v-for="subspecialty in schedule.doctor.subspecialties" v-bind:key="subspecialty.id"
                        type='is-warning'
                    >
                      {{subspecialty.name}}
                    </b-tag>
                  </b-field>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-6">
                  <b-field label="Paciente">
                    <b-button
                        size="is-small"
                        type="is-info"
                        icon-left="external-link-square-alt"
                        @click="openUrl('/patients/'+schedule.patient.id)"
                    >
                      ver paciente
                    </b-button>
                  </b-field>
                  <b-input
                      v-if="schedule.patient.user"
                      name="name"
                      required
                      label="Paciente"
                      v-model="schedule.patient.name"
                      disabled
                      :icon-right-clickable="true"
                      icon-right="external-link-square-alt"
                      @icon-right-click="openUrl('/patients/'+schedule.patient.id)"
                  />
                </div>
                <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit">
                  <h2 class="subtitle">Dados da Consulta</h2>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-3">
                  <b-field label="Início">
                    <b-datetimepicker
                      disabled
                      class="m-1"
                      v-model="schedule.start"
                      rounded
                      placeholder="Início"
                      icon="calendar"
                      :locale="'pt-BR'"
                      :timepicker="{ enableSeconds, hourFormat }"
                      horizontal-time-picker
                    >
                    </b-datetimepicker>
                  </b-field>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-3">
                  <b-field label="Fim">
                    <b-datetimepicker
                      disabled
                      class="m-1"
                      v-model="schedule.end"
                      rounded
                      placeholder="Fim"
                      icon="calendar-alt"
                      :locale="'pt-BR'"
                      :timepicker="{ enableSeconds, hourFormat }"
                      horizontal-time-picker
                    >
                    </b-datetimepicker>
                  </b-field>
                </div>
                <div class="col-sm-12 col-lg-12 col-xl-2">
                  <b-checkbox
                    disabled
                    name="fit"
                    true-value="1"
                    false-value="0"
                    v-model="schedule.fit"
                    class="form-checkbox"
                    >Encaixe
                  </b-checkbox>
                </div>
                <div class="col-sm-12 col-lg-12 col-xl-2">
                  <b-checkbox
                    disabled
                    name="internal"
                    true-value="1"
                    false-value="0"
                    v-model="schedule.internal"
                    class="form-checkbox"
                    >Interno
                  </b-checkbox>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-2">
                  <app-input
                    name="appointment_type"
                    required
                    label="Tipo"
                    v-model="schedule.appointment.appointment_type.name"
                    disabled
                  />
                </div>
                <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit">
                  <h2 class="subtitle">Financeiro</h2>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-3">
                  <app-input
                    name="value"
                    required
                    label="Valor"
                    v-model="schedule.value"
                    disabled
                  />
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-3">
                  <app-input
                    name="value"
                    required
                    label="Taxa"
                    v-model="schedule.value_fee"
                    disabled
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SchedulesService from '@/services/schedules.service.js';
import StatesService from '@/services/states.service';
import CitiesService from '@/services/cities.service';
import MASKS from '@/constants/masks.constant';

export default {
  data: () => ({
    errors: {},
    MASKS: MASKS,
    isLoading: true,
    isLoadingCity: false,
    title: '',
    schedule: {
      doctor: { user: {} },
      patient: { user: {} },
      appointment: { appointment_type: {} },
    },
    schedules: [],
    states: [],
    cities: [],
    state_id: null,
    city_id: null,
    enableSeconds: false,
    hourFormat: '24',
  }),
  validations: {},
  methods: {
    statesGetter: StatesService.search,
    citiesGetter: CitiesService.search,
    openUrl(url){
      this.$router.push(url);
    },
    loadSchedule(id) {
      this.isLoading = true;
      if (typeof id != 'undefined') {
        SchedulesService.getId(id)
          .then(({ data: data }) => {
            this.schedule = data;
            this.schedule.start =
              this.schedule.start != null
                ? new Date(this.schedule.start.replace('-', '/'))
                : null;
            this.schedule.end =
              this.schedule.end != null
                ? new Date(this.schedule.end.replace('-', '/'))
                : null;
            this.schedule.value =
              this.schedule.value != null
                ? 'R$ ' +
                  parseFloat(this.schedule.value)
                    .toFixed(2)
                    .replace(',', '.')
                    .replace('.', ',')
                : '0,00';
            this.schedule.value_fee =
              this.schedule.value_fee != null
                ? 'R$ ' +
                  parseFloat(this.schedule.value_fee)
                    .toFixed(2)
                    .replace(',', '.')
                    .replace('.', ',')
                : '0,00';
          })
          .finally(() => (this.isLoading = false));
      }

      this.isLoading = false;
    },
  },

  mounted() {
    const id = this.$route.params.id;
    this.id = this.$route.params.id;
    this.loadSchedule(id);
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  display: flex;

  &__image {
    margin-right: 24px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
    height: 120px;
    width: 100%;
    height: 100%;
    text-align: center;

    img {
      object-fit: cover;
    }
  }

  &__info {
    h2 {
      font-size: 1.4rem;
    }

    .links {
      margin-top: 8px;

      .button:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
.field-specialty{
  margin-bottom: 0.25rem;
}
</style>
